import { load } from "@shopify/theme-sections";
import isMobile from 'is-mobile'
import revive from '@/scripts/extra/revive'
import '../scripts/sections/sections-age-check.bundle.js'
import '../scripts/sections/modal-signup.js'
import '../scripts/sections/start-screen.js'

// Islands
const islands = {
  ...import.meta.glob('@/scripts/sections/*.js'),
  ...import.meta.glob('@/scripts/templates/*.js'),
  ...import.meta.glob('@/scripts/modules/*.js'),
}

revive(islands)

// Function to load all sections
const loadSections = () => {
  load("*");
  console.log('Loaded!!!');
};

// eslint-disable-next-line no-prototype-builtins
if (!HTMLElement.prototype.hasOwnProperty('inert')) {
  import(/* @vite-ignore */ flu.chunks.polyfillInert)
}

if (isMobile({ tablet: true, featureDetect: true })) {
  add(document.body, 'is-mobile')
}

// Call above function either immediately or bind on loaded event
if (
  document.readyState === "complete" ||
  document.readyState === "interactive"
) {
  loadSections();
} else {
  listen(document, "DOMContentLoaded", loadSections);
}

